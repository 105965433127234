import React from "react"
import Page from "../../components/page"
import TextLeft from "../../components/textLeft"
import { graphql } from "gatsby"
import TextRight from "../../components/textRight"

export default function HowItWorks({ location, data }) {
  return (
    <Page title={"How it works – PhotoTailors"} location={location}>
      <TextLeft
        title={<div>online <br/> platform</div>}
        subtitle="easy to use"
        text="Have you already picked your selection of photos? Now it’s time to shape up your album! Personalise your album to fit your unique personality: choose the colours and the material, then upload your spreads and voila! It’s all ready for shipping – and you can track the order online."
        data={data}
        image={'albumsHowOnline'}
      />
      <TextRight
        title={<div>handmade</div>}
        subtitle="craftsmanship"
        text="Each album is a masterpiece: a fusion between your picks and the work of our top skilled craftsmen. Once you picked your choice of materials and colours, our talented artisans will create your album by hand. We place extreme emphasis on the attention to details so you get a perfect finished product – every time."
        data={data}
        image={'albumsHowHandmade'}
      />
      <TextLeft
        title={<div>order</div>}
        subtitle="personalization"
        text="We’ve talked about choosing materials and colours and your own selection of photos. But we wanted to take everything one step further. Now, you can also choose between UV printing or embossing, making your album more… you."
        data={data}
        image={'albumsHowOrder'}
      />
      <TextRight
        title={<div>WORLDWIDE <br/> SHIPPING</div>}
        subtitle="regular or express"
        text="Printed images are fused onto a hard heavyweight board core. The thick, robust core doesn’t bend or curl, giving your album an elegant feel. It is the elite’s preferred choice, reserved only for your most exceptional and defining moments."
        data={data}
        image={'albumsHowShipping'}
      />
      <TextLeft
        title={<div>TURNAROUND <br/> time</div>}
        subtitle="fast"
        text="The turnaround time is five days from the moment you place your order. Prick up your ears: the doorbell might ring sooner than you expected!"
        data={data}
        image={'albumsHowTurnaround'}
      />

    </Page>
  )
}


export const query = graphql`
  query {
    albumsHowOnline: file(relativePath: { eq: "albumsHowOnline.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
   albumsHowHandmade: file(relativePath: { eq: "albumsHowHandmade.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  
  albumsHowOrder: file(relativePath: { eq: "albumsHowOrder.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
   
   albumsHowShipping: file(relativePath: { eq: "albumsHowShipping.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  } 
  
  albumsHowTurnaround: file(relativePath: { eq: "albumsHowTurnaround.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  } 
  
  
   
  }
`